.expenses-list {
  list-style: none;
  padding: 0;
}

.expenses-list__fallback {
  color: white;
  text-align: center;
}

.expenses-item__pragraph {
  color: wheat;
  text-align: center;
  font-size: 0.8rem;
}
